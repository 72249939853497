import { IonContent, IonRow } from "@ionic/react";
import React, { useEffect } from "react";
import { API_URL } from "../../../actions/settings";
import { useAppSelector } from "../../../Hooks";
import { selectUser } from "../../../reducers/UserReducer";
import QuicksightEmbed from "../../extras/QuicksightEmbed";
import { isStaff } from "../../extras/Functions";
import { Redirect } from "react-router";

interface Props {
  id?:string |null,
  singleBuilding?:Boolean
}

async function getDashUrl(id: any, token: string) {
  return fetch(`${API_URL}/buildings/getDashUrl/${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: token,
    },
  }).then((data) => data.json());
}

const CostAvoidanceDashboard:React.FC<Props> = ({id,singleBuilding=false}) => {
  const user = useAppSelector(selectUser);

  useEffect(() => {

    const handleDash = async (dashID: string,sheet:string,) => {
      const dashboard = new QuicksightEmbed();
      await dashboard.init();
      const token = localStorage.getItem("token") || "";
      const res = await getDashUrl(dashID, `Token ${token.slice(1, -1)}`);
      if(singleBuilding){
        dashboard.changeSettings({
          url: res[0],
          container: `#constAvoidanceDash`,
          exportDash: false,
          undoRedo: false,
          reset: false,
          sheetID:sheet
        });
        dashboard.setParameters({
          BuildingUuid:id,
        })
      }
      else{
        dashboard.changeSettings({
          url: res[0],
          container: `#constAvoidanceDash`,
          exportDash: true,
          undoRedo: true,
          reset: true,
          sheetID:sheet
        });
      }
      await dashboard.embedDash();
    };


    if (isStaff(user) && !singleBuilding) {
      handleDash("0b2f7990-b564-4ba9-b6d3-faee4000ed32","0b2f7990-b564-4ba9-b6d3-faee4000ed32_e7bd48dd-5f3f-47ed-a31a-e7c939b51d88");
    }
    if (singleBuilding){
      handleDash("0b2f7990-b564-4ba9-b6d3-faee4000ed32","0b2f7990-b564-4ba9-b6d3-faee4000ed32_e7bd48dd-5f3f-47ed-a31a-e7c939b51d88")
    }
  }, [id,user,singleBuilding]);

  if (user.first_name !== "" && !isStaff(user) && !singleBuilding) {
    return <Redirect to="/403" />;
  }

  return (
    <>
      <IonRow hidden={singleBuilding?true:false} className="ion-justify-content-center">
        <span className="main-heading">Cost Avoidance Dashboard</span>
      </IonRow>
      <IonContent id="constAvoidanceDash"></IonContent>
    </>
  );
};

export default CostAvoidanceDashboard;
