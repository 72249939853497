import { IonButton, IonRow } from "@ionic/react"
import { useEffect } from "react"
interface props{
    setPage:any
}

const EnergyPerformanceMenu: React.FC<props>= ({setPage}) => {
    type Choices = 'home' | 'targets' |'costAvoidance' | 'UtilityBills'
    const handleMenuClick = (e:any,page:Choices) =>{
        setPage(page)
        const buttons: NodeListOf<Element>  = document.querySelectorAll(".energy-performance-menu-button")
        buttons.forEach((button:any)=>{
            button.classList.remove('selected')
        })
        e.target.classList.add('selected')

    }

    useEffect(()=>{
        const button = document.querySelector('#energy-performance-home')
        button?.classList.add('selected')
    },[])

    return(
        <IonRow className="energy-performance-menu-buttons" style={{borderBottom:"2px solid grey"}}>
            <IonButton className="energy-performance-menu-button" id="energy-performance-home" fill='clear' color="light" onClick={(e)=>handleMenuClick(e,'home')}>Energy Efficiency</IonButton>
            <IonButton className="energy-performance-menu-button" fill='clear' color="light" onClick={(e)=>handleMenuClick(e,'targets')}>Energy Targets</IonButton>
            <IonButton className="energy-performance-menu-button" fill='clear' color="light" onClick={(e)=>handleMenuClick(e,'costAvoidance')}>Cost Avoidance</IonButton>
            <IonButton className="energy-performance-menu-button" fill='clear' color="light" onClick={(e)=>handleMenuClick(e,'UtilityBills')}>Utility Bills</IonButton>
        </IonRow>
    )
}

export default EnergyPerformanceMenu